$(document).ready(function () {
    /* var swiperBanner = new Swiper(".banner-slider-container", {
        slidesPerView: 1,
        loop: true
    }); */
    var swiperMember = new Swiper(".member-slider-container", {
        slidesPerView: getCount(),
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true,
            dynamicMainBullets: 4,
            clickable: true
        }
    });
    var swiperText = new Swiper(".text-slider-container", {
        autoHeight: true,
        pagination: {
            el: ".text-slider-container .swiper-pagination",
        },
    });
    var swiper = new Swiper(".swiper-blog", {
        pagination: {
            el: ".swiper-pagination",
        },
    });
});

function getCount() {
    var windowWidth = window.innerWidth;
    if (window.innerWidth > 991) {
        var count = 6
    } else {
        if (window.innerWidth > 576) {
            var count = 6
        } else {
            var count = 2
        }
    }
    return count;
}

/*
  var swiper = new Swiper('.swiper-container', {
    slidesPerView: 3,
    direction: getDirection(),
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      resize: function () {
        swiper.changeDirection(getDirection());
      },
    },
  });

  function getDirection() {
    var windowWidth = window.innerWidth;
    var direction = window.innerWidth <= 760 ? 'vertical' : 'horizontal';

    return direction;
  }*/